import Head from 'next/head'

export default function CommonMeta({
    title = 'TVアニメ『惑星のさみだれ』公式サイト',
    description = '「惑星（ほし）のさみだれ」2022年夏TVアニメ放送開始！',
}) {
    return (
        <Head>
            <title>{title}</title>
            <meta property="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={`${process.env.NEXT_PUBLIC_SITE_URL}/images/ogp.png`} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="viewport" content="width=device-width,initial-scale=1.0,viewport-fit=cover" />
        </Head>
    )
}
