import { VFC } from 'react'

export type CNewsCardProps = {
    onClick?: () => void
    day: string
    title: string
    opacity: boolean
}

export type CNewsCardViewProps = {} & CNewsCardProps

export const NewsCard: VFC<CNewsCardViewProps> = ({ day, title, onClick, opacity }) => {
    return opacity ? (
        <div
            onClick={onClick}
            className="flex justify-between cursor-pointer items-center bg-c-secondary md:p-6 p-4 font-light md:font-normal md:mt-6 mt-4 md:h-[112px] h-[80px] overflow-hidden bg-opacity-80 font-RocknRoll">
            <div className="flex w-full flex-col justify-start self-center overflow-hidden text-[#05151F]">
                <p className="w-max md:text-xl text-sm text-c-primary">{day}</p>
                <p className="w-full md:text-2xl text-base md:h-9 h-6 truncate">{title}</p>
            </div>
            <span className="">
                <img src="images/news-arrow-blue.svg" alt="矢印" className="md:h-auto h-6 ml-3" />
            </span>
        </div>
    ) : (
        <div
            onClick={onClick}
            className="flex justify-between cursor-pointer items-center bg-c-secondary md:p-6 p-4 font-light md:font-normal md:mt-6 mt-4 md:h-[145px] h-[80px] overflow-hidden font-RocknRoll">
            <div className="flex w-full flex-col justify-start self-center overflow-hidden">
                <p className="w-full md:text-xl text-sm text-c-primary">{day}</p>
                <p className="w-full md:text-2xl text-base md:h-9 h-6 truncate">{title}</p>
            </div>
            <span className="">
                <img src="images/news-arrow-blue.svg" alt="矢印" className="md:h-auto h-6 ml-3" />
            </span>
        </div>
    )
}

export default NewsCard
