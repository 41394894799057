import axiosClient from '@aspida/axios'
import axios from 'axios'

import api from '~/apis/$api'

export const createApiClient = () => {
    const baseUrl = process.env.BASE_URL || process.env.NEXT_PUBLIC_BASE_URL || ''

    return api(
        axiosClient(
            axios.create({
                baseURL: baseUrl,
            }),
        ),
    )
}
