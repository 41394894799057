import dayjs from 'dayjs'

export const formatDay = (dateStr?: string | null): string => {
    return dateStr ? dayjs(dateStr).format('YYYY/MM/DD') : ''
}
export const formatUsDay = (dateStr?: string | null): string => {
    return dateStr ? dayjs(dateStr).format('YYYY.MM.DD') : ''
}

export const formatDayBetween = (startDateStr?: string, endDateStr?: string): string => {
    if (startDateStr === endDateStr) return startDateStr ? dayjs(startDateStr).format('YYYY/MM/DD') : ''
    else {
        return (
            (startDateStr ? dayjs(startDateStr).format('YYYY/MM/DD') : '') +
            '-' +
            (endDateStr ? dayjs(endDateStr).format('YYYY/MM/DD') : '')
        )
    }
}
