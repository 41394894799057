import { VFC } from 'react'

export type CVerticalTitleProps = {
    title: string
    largeTitle?: boolean
}

export type CVerticalTitleViewProps = {} & CVerticalTitleProps

export const VerticalTitle: VFC<CVerticalTitleViewProps> = ({ title, largeTitle }) => {
    return largeTitle ? (
        <div className="rotate-90 text-c-secondary font-bold w-max h-20 md:text-[96px] text-2xl tracking-widest font-RocknRoll">
            <p>{title}</p>
        </div>
    ) : (
        <div className="rotate-90 text-c-secondary font-bold w-max h-20 md:text-5xl text-2xl tracking-widest font-RocknRoll">
            <p>{title}</p>
        </div>
    )
}

export default VerticalTitle
