import Link from 'next/link'
import { VFC } from 'react'

export type CButtonProps = {
    onClick?: () => void
    href?: string
    label: string
}

export type CButtonViewProps = {} & CButtonProps

export const Button: VFC<CButtonViewProps> = ({ label, href, onClick }) => {
    return href ? (
        <Link href={href} passHref>
            <div className="w-full text-c-secondary m-auto cursor-pointer font-bold font-RocknRoll md:text-3xl text-xl">
                <p className="flex justify-end">{label}</p>
            </div>
        </Link>
    ) : (
        <button onClick={onClick}>
            <div className="w-full bg-c-primary text-c-white py-3 md:px-36 px-28 cursor-pointer rounded-md font-bold">
                <p className="flex justify-center text-sm md:text-xl">{label}</p>
            </div>
        </button>
    )
}

export default Button
