import styled from '@emotion/styled'
import type { NextPage } from 'next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import type { VFC } from 'react'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { TwitterTimelineEmbed } from 'react-twitter-embed'

import { ArticleList } from '~/apis/@types'
import { Methods as Methods0 } from '~/apis/api/article'
import Button from '~/components/global/CButton'
import NewsCard from '~/components/global/CNewsCard'
import { VerticalTitle } from '~/components/global/CVerticalTitle'
import { TopLayout } from '~/components/layout/Top'
import { createApiClient } from '~/utils/createApiClient'
import { formatUsDay } from '~/utils/date'

export type TopViewProps = {
    onClickArticle: (articleId: string) => void
    onClickToShindan: () => void
    article?: ArticleList
}

const spWidth = 599
const tabletWidth = 912

const Onair = styled.div`
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
    line-height: 90px;
    letter-spacing: 0.1em;
    font-size: clamp(2.3rem, 6.2vw, 3.8rem);
`
const OnairCompany = styled.div`
    line-height: 70px;
    letter-spacing: 0.1em;
    font-size: clamp(1.5rem, 3vw, 2.4rem);
    @media (max-width: 767px) {
        margin-top: -16px;
    }
`
const OnairAttention = styled.div`
    line-height: 29px;
    letter-spacing: 0.1em;
    font-size: clamp(0.72rem, 4vw, 1.25rem);
    margin-top: 32px;
    @media (max-width: 767px) {
        margin-top: 12px;
    }
`

const News = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    max-width: 900px;
    width: 75%;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    color: #000000;
    margin: auto;
    margin-top: 112px;
    @media (max-width: ${tabletWidth}px) {
        font-size: 16px;
        margin-top: 72px;
    }
    @media (max-width: ${spWidth}px) {
        font-size: 16px;
        margin-top: 80px;
    }
`
const SeeMore = styled.div`
    display: flex;
    justify-content: end;
    margin: auto;
    max-width: 900px;
    width: 75%;
    margin-top: 24px;
    @media (max-width: ${tabletWidth}px) {
        margin-top: 32px;
    }
    @media (max-width: ${spWidth}px) {
        margin-top: 16px;
    }
`
const Story = styled.div`
    background: url(/images/top-hammer.png) no-repeat center;
    width: 100vw;
    color: #ffffff;
    margin-top: 48px;
    font-size: clamp(1.5rem, 5.7vw, 3.6rem);
    text-shadow: #036eb7 0px 0px 30px, #036eb7 0.540302px 0.841471px 0px, #036eb7 -0.416147px 0.909297px 0px,
        #036eb7 -0.989992px 0.14112px 0px, #036eb7 -0.653644px -0.756802px 0px, #036eb7 0.283662px -0.958924px 0px,
        #036eb7 0.96017px -0.279415px 0px;
    @media (max-width: ${spWidth}px) {
        background: url(/images/top-hammer.png) no-repeat top;
        padding: 0 4%;
        margin-top: 0px;
    }
`

const StoryText = styled.div`
    font-weight: 400;
    font-size: clamp(1rem, 3.2vw, 2rem);
    line-height: 72px;
    text-align: center;
    margin: 78px 14%;
    @media (max-width: ${spWidth}px) {
        margin: 32px 12px 0px;
        font-size: 16px;
        line-height: 200%;
    }
`
const Twitter = styled.div`
    position: relative;
    padding: 32px;
    max-width: 960px;
    margin: auto;
    @media (max-width: 1160px) {
        width: 75%;
    }
    @media (max-width: ${spWidth}px) {
        padding: 16px 12px;
    }
    @media (max-width: 382px) {
        padding: 16px 12px;
    }
`

const BookBanner = styled.div`
    display: flex;
    margin: auto;
    margin-top: 240px;
    margin-right: 40px;
    cursor: pointer;
    max-width: 900px;
    height: auto;
    @media (max-width: ${tabletWidth}px) {
        max-width: 640px;
        margin-top: 120px;
        margin-right: 20px;
    }
    @media (max-width: ${spWidth}px) {
        margin: 0px 8px;
    }
`
const ShindanBanner = styled.div`
    display: flex;
    margin: auto;
    margin-top: 240px;
    margin-left: 40px;
    cursor: pointer;
    max-width: 900px;
    height: auto;
    @media (max-width: ${tabletWidth}px) {
        max-width: 640px;
        margin-top: 120px;
        margin-left: 20px;
    }
    @media (max-width: ${spWidth}px) {
        margin: 24px 8px;
    }
`

export const TopPageView: VFC<TopViewProps> = ({ article, onClickArticle, onClickToShindan }) => {
    return (
        <TopLayout>
            <div className="pt-[12.5vw] md:pt-0 pb-[118.125vw] md:pb-[64.583vw]">
                <div className="md:py-12">
                    <Onair className="font-RocknRoll">
                        <p className="md:mt-8 mt-6">
                            <span className="text-c-secondary">2022</span>
                            <span className="md:text-3xl text-2xl">年</span>
                            <span className="text-c-secondary">7</span>
                            <span className="md:text-3xl text-2xl">月</span>
                        </p>
                        <OnairCompany>
                            <div className="md:flex justify-center">
                                <p>MBS/TBS系</p>
                                <p className="md:mt-0 -mt-8">アニメイズム枠、</p>
                                <p className="md:mt-0 -mt-8">とちぎテレビ、</p>
                            </div>
                            <div className="md:flex">
                                <p className="md:mt-0 -mt-8">BS11、AT-X、</p>
                                <p className="md:mt-0 -mt-8">テレビ北海道</p>
                                <p className="md:mt-0 -mt-8">にて放送開始！</p>
                            </div>
                        </OnairCompany>
                        <OnairAttention>※放送情報は変更となる場合がございます</OnairAttention>
                    </Onair>
                    <News>
                        <div className="absolute md:top-24 top-8 md:-left-44 -left-24">
                            <VerticalTitle title={'ニュース'} />
                        </div>
                        <>
                            {article?.list.length ? (
                                <div>
                                    {article?.list.map((it) => (
                                        <div key={it.uuid} onClick={() => onClickArticle(it.uuid)}>
                                            <NewsCard day={formatUsDay(it.publish_start_at)} title={it.title} opacity={false} />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="flex justify-center">
                                    <p>記事がありません</p>
                                </div>
                            )}
                        </>
                    </News>
                    <SeeMore>
                        <Button href="/news" label="MORE >" />
                    </SeeMore>
                </div>
                <Story className="font-RocknRoll">
                    <div className="flex flex-col justify-center py-[368px] md:py-[200px] lg:py-[287px]">
                        <p className="md:w-max w-auto m-auto">イントロダクション</p>
                        <StoryText>
                            ごく普通の平凡大学生だった雨宮夕日は、ある日現れた喋るトカゲに「地球の危機」を救う協力を依頼される。拒否する間もなく獣の騎士団の一員となった夕日は、指輪の力で超能力・掌握領域が与えられるが、早くも敵に襲われてしまう。絶体絶命のその時、夕日を救ったのはなんとお隣に住む少女・さみだれだった。救世主の降臨と思いきや、実はさみだれは地球征服を企む魔王だった……。
                            <p className="md:mt-12 mt-6">
                                そのでたらめな強さと魔王的魅力に惹かれた夕日は彼女の下僕となり、彼女が望む別の思惑に加担する事になる。
                            </p>
                        </StoryText>
                    </div>
                </Story>
                <Twitter className="bg-c-secondary">
                    <div className="absolute md:top-28 -top-2 md:-left-56 -left-32">
                        <VerticalTitle title={'公式Twitter'} />
                    </div>
                    <div className="absolute md:-top-14 -top-8 left-1 md:p-0 md:w-48 w-28">
                        <img src="/images/noi.svg" alt="ノイのシルエット" />
                    </div>
                    <div className="max-w-[900px] mb-154 mx-auto">
                        <TwitterTimelineEmbed
                            onLoad={function noRefCheck() {}}
                            lang="ja"
                            options={{
                                height: 379,
                                width: '100%',
                            }}
                            screenName="AnimeSamidare"
                            sourceType="profile"
                        />
                    </div>
                </Twitter>
                <div className="md:flex lg:px-44 md:px-28 md:mt-0 mt-20">
                    <BookBanner>
                        <Link passHref href={'https://www.shonengahosha.co.jp/topics_Info.php?id=7850'}>
                            <div className="flex m-auto">
                                <img src="images/banner-book-pc.png" alt="原作バナー" srcSet="images/banner-book.png 900w" />
                            </div>
                        </Link>
                    </BookBanner>
                    <ShindanBanner onClick={onClickToShindan}>
                        <div className="flex m-auto">
                            <img
                                src="images/banner-kishimaker-pc.png"
                                alt="騎士団メーカーバナー"
                                srcSet="images/banner-kishimaker.png 900w"
                            />
                        </div>
                    </ShindanBanner>
                </div>
            </div>
        </TopLayout>
    )
}

export const TopPage: NextPage = () => {
    const router = useRouter()
    const apiClient = createApiClient()
    const { page, limit } = router.query
    const articleParams = useMemo<Methods0['get']['query']>(() => {
        return {
            page: parseInt((page ?? '1') as string) ?? 1,
            limit: parseInt((limit ?? '3') as string) ?? 1,
        }
    }, [page, limit])

    const { data } = useQuery(
        [articleParams],
        async () => {
            const { body } = await apiClient.api.article.get({
                query: articleParams,
            })
            return body
        },
        {
            // initialData: article,
            keepPreviousData: false,
        },
    )
    const onClickArticle = async (newsId: string) => {
        await router.push({
            pathname: 'news/[newsId]',
            query: {
                newsId: newsId,
            },
        })
    }
    const onClickToShindan = () => {
        router.push({
            pathname: '/shindan',
        })
    }

    return <TopPageView article={data} onClickArticle={onClickArticle} onClickToShindan={onClickToShindan} />
}

export default TopPage
