import { Link } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { gsap } from 'gsap'
import Image from 'next/image'
import { nextTick } from 'process'
import { ReactNode, useEffect, VFC } from 'react'

import CommonMeta from '../head/commonMeta'

export type TopLayoutProps = {
    children: ReactNode
}

const spWidth = 599
const tabletWidth = 820
const pcWidth = 1024

const Bg = styled.main`
    background: url(/images/bg-bottom.png) no-repeat bottom,
        linear-gradient(180deg, #2d1627 0%, #0c062d 40%, #0c062d 37.51%, #4083e8 53.65%, #87ccfd 71.73%);
    background-size: contain;
    width: 100vw;
    min-height: 100vh;
    background-attachment: local;
    /* @media (max-width: ${tabletWidth}px) {
        background: url(/images/bg-bottom.png) no-repeat bottom, linear-gradient(180deg, #2d1627 0%, #150a50 45%, #83c9fd 70%, #83c9fd 100%);
        // 見切れるようにするためあえて固定値
        background-size: 900px;
    } */
    @media (max-width: ${spWidth}px) {
        background: url(/images/bg-bottom.png),
            linear-gradient(
                180deg,
                rgba(45, 22, 39, 1) 0%,
                rgba(21, 10, 80, 1) 50%,
                rgba(21, 10, 80, 1) 240vh,
                rgba(131, 201, 253, 1) 60%,
                rgba(131, 201, 253, 1) 100%
            );
        background-position: bottom;
        background-repeat: no-repeat;
        // 見切れるようにするためあえて固定値
        background-size: 600px;
    }
`

const Stars = styled.div`
    position: absolute;
    top: 40vw;
    left: 0;
    height: 200vw;
    margin: auto;
    transform: rotate(45deg);
    width: 100vw;
`

const Star = styled.div`
    position: absolute;
    width: 1px;
    height: 80%;
    top: 0;
    left: 50%;

    &::after {
        content: '';
        display: block;
        position: absolute;
        height: 100vh;
        width: 100%;
        top: -60%;
        left: 0;
        background-color: #ffffff;
        animation: drop 6s 0s infinite;
        animation-fill-mode: forwards;
    }

    :first-of-type {
        margin-left: -45%;
        &::after {
            animation-delay: 1.2s;
        }
    }
    :nth-of-type(2) {
        margin-left: 12px;
        &::after {
            zoom: 0.5;
            animation-delay: -0.2s;
        }
    }
    :nth-of-type(3) {
        margin-left: -28%;
        &::after {
            animation-delay: 4.5s;
        }
    }
    :nth-of-type(4) {
        margin-left: 18%;
        &::after {
            animation-delay: 3.3s;
        }
    }
    :last-of-type {
        margin-left: 35%;
        &::after {
            animation-delay: 2.5s;
        }
    }

    @keyframes drop {
        0% {
            top: -45%;
            height: 100vw;
        }
        80% {
            height: 0;
        }
        100% {
            height: 0;
            top: 100%;
        }
    }
`

const KeyVisualArea = styled.div`
    margin-top: -250vw;
    display: flex;
    position: relative;
    justify-content: center;
`

const KeyVisual = styled.div`
    position: relative;
    margin: 64px 5vw;
    max-width: 1800px;
    width: 90vw;
`

const KeyVisualImg = styled.img`
    position: absolute;
    opacity: 0;
    bottom: 50px;
    max-width: 1800px;
    width: 100%;
`

const Footer = styled.footer`
    text-align: center;
    font-size: 0.92rem;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 0.1em;
    width: 100vw;
    padding-bottom: 40px;
    @media (max-width: ${spWidth}px) {
        padding-bottom: 16px;
        font-size: 0.62rem;
    }
    @media (orientation: landscape) and (max-width: ${pcWidth}px) {
        font-size: 0.62rem;
    }
`

export const TopLayout: VFC<TopLayoutProps> = ({ children }) => {
    useEffect(() => {
        nextTick(async () => {
            const kvs = ['.kv_bottom', '.kv_mid', '.kv_top', 'logo']
            // eslint-disable-next-line no-restricted-syntax
            for (const kv of kvs) {
                await gsap.to(kv, {
                    opacity: 1,
                    delay: 0.3,
                    duration: 0.6,
                    bottom: 0,
                })
            }
            await gsap.to('.logo', {
                opacity: 1,
                duration: 0.8,
            })
        })
    })

    return (
        <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }} transition={{ duration: 0.3 }}>
            <Bg>
                <CommonMeta />
                <div className="relative w-screen h-[250vw] overflow-hidden pointer-events-none">
                    <Stars>
                        <Star />
                        <Star />
                        <Star />
                        <Star />
                        <Star />
                        <Star />
                    </Stars>
                </div>
                <KeyVisualArea>
                    <KeyVisual>
                        <img className="kv_bottom w-full" src="/images/top/kv_bottom.jpg" alt="キービジュアル" />
                        <KeyVisualImg className="kv_mid" src="/images/top/kv_mid.png" alt="キービジュアル" />
                        <KeyVisualImg className="kv_top" src="/images/top/kv_top.png" alt="キービジュアル" />
                    </KeyVisual>
                    <h1 className="logo opacity-0 absolute w-4/5 max-w-xl md:h-[52vw] h-[68vw] md:bottom-[0.6%] -bottom-[12%]">
                        <Link href="/">
                            <Image src="/images/logo.svg" alt="惑星（ほし）のさみだれ" layout="fill" objectFit="contain" />
                        </Link>
                    </h1>
                </KeyVisualArea>
                <div className="w-full relative z-[1]">{children}</div>
                <Footer>
                    <small>©水上悟志・少年画報社／ある時代の指輪の騎士団</small>
                </Footer>
            </Bg>
        </motion.div>
    )
}
